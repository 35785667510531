import React, { useEffect, useState } from 'react';

import Form from 'react-bootstrap/Form';

import { Typeahead } from 'react-bootstrap-typeahead';

import ClearButton from './Shared/ClearButton';

const LocationFilter = (props) => {
  const [cities, setCities] = useState([]);

  const [query, setQuery] = useState('');

  useEffect(() => {
    if (query.length < 3) {
      setCities([]);
    } else {
      fetch(`/api/v1/cities?query=${query}`)
        .then((res) => res.json())
        .then((data) => setCities(data));
    }
  }, [query]);

  const citySelected = !!Object.keys(props.city).length;
  const selected = citySelected ? [props.city] : [];

  const filterByCallback = (option, props) => {
    return option.name.toLowerCase().indexOf(props.text.toLowerCase()) !== -1;
  };

  const onChange = (item) => {
    props.setCity(item[0] || {});
  };

  const onClick = () => {
    props.setCity({});
    setQuery('');
  };

  const onInputChange = (text) => {
    setQuery(text);
  };

  return (
    <Form.Group className="d-flex gap-1 w-100">
      <div className="position-relative w-75">
        <Typeahead
          emptyLabel="Geen zoekresultaten"
          filterBy={filterByCallback}
          id="custom-filtering-example"
          labelKey="name"
          onChange={onChange}
          onInputChange={onInputChange}
          options={cities}
          placeholder="Zoek op plaatsnaam..."
          selected={selected}
          renderMenuItemChildren={(option) => (
            <div>
              {option.name}
              <div>
                <small>{option.state}</small>
              </div>
            </div>
          )}
        />
        {citySelected && <ClearButton onClick={onClick} />}
      </div>
      <Form.Select className="w-25" onChange={(e) => props.setDistance(e.target.value)}>
        <option value={0}>+0km</option>
        <option value={25}>+25km</option>
        <option value={50}>+50km</option>
        <option value={100}>+100km</option>
      </Form.Select>
    </Form.Group>
  );
};

export default LocationFilter;
